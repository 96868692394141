import { toKebabCase } from 'helpers/utils'
import React from 'react'
import Form from 'react-bootstrap/Form'

function NumberField({
  params,
  setParams,
  accessorKey,
  title,
  showTitle = false,
  placeholder = ''
}) {
  return (
    <>
      <label
        className={`mr-2 fw-light text-muted ${showTitle ? '' : 'sr-only'}`}
      >
        <small>{title}</small>
      </label>
      <Form.Control
        type="number"
        name={toKebabCase(title)}
        placeholder={placeholder}
        value={params[accessorKey]}
        onChange={(e) =>
          setParams({ ...params, [accessorKey]: e.target.value })
        }
      />
    </>
  )
}

export default NumberField
