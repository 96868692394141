import React from 'react'
import useDashboardStats from 'api/hooks/use-dashboard-stats'
import StatCard from './stat-card'
import CenteredSpinner from 'shared/centered-spinner'
import { Col } from 'react-bootstrap'
import { pickPresentParams } from 'helpers/utils'

function DashboardStats({
  params,
  setParams,
  sectionUnit,
  sectionName,
  isFilterOpen,
  columns
}) {
  const queryParams = {
    dashboardId: params.dashboardId,
    section_id: params.section_id,
    time_period: params.time_period,
    minimum_amount: params.minimum_amount,
    maximum_amount: params.maximum_amount,
    vendors: params.vendors
      .filter((v) => v.options === 'include')
      .map((v) => v.id)
      .join('+'),
    excluded_vendors: params.vendors
      .filter((v) => v.options === 'exclude')
      .map((v) => v.id)
      .join('+'),
    employees: params.employees
      .filter((e) => e.options === 'include')
      .map((e) => e.id)
      .join('+'),
    excluded_employees: params.employees
      .filter((e) => e.options === 'exclude')
      .map((e) => e.id)
      .join('+'),
    analytics: params.analytics.map((a) => a.id).join('+'),
    org_ids: params.orgs.join('+'),
    object_ids: params.objects.join('+'),
    project_ids: params.projects.join('+'),
    seg_one_ids: params.segOnes.join('+'),
    seg_two_ids: params.segTwos.join('+'),
    seg_three_ids: params.segThrees.join('+'),
    seg_four_ids: params.segFours.join('+'),
    seg_five_ids: params.segFives.join('+'),
    seg_six_ids: params.segSixes.join('+'),
    seg_seven_ids: params.segSevens.join('+'),
    seg_eight_ids: params.segEights.join('+'),
    po_is_pending_workflow: params?.poIsPendingWorkflow,
    po_min_req_to_po_creation: params?.poMinReqToPoCreation,
    po_po_status_code: params?.poStatusCode,
    ap_min_invoice_to_entry_days: params?.apMinInvoiceToEntryDays,
    ap_min_entry_to_payment_days: params?.apMinEntryToPaymentDays,
    ap_min_total_days_to_payment: params?.apMinTotalDaysToPayment
  }
  const options = {
    enabled: !isFilterOpen && params.section_id !== ''
  }
  const { isSuccess, isLoading, data } = useDashboardStats({
    params: pickPresentParams(queryParams),
    options
  })

  const getStatCard = () => {
    return columns.map((column) => {
      return {
        title: column.name,
        value: data[column.name],
        dataType: column.dataType
      }
    })
  }
  const stats = isSuccess ? getStatCard() : []

  return (
    <>
      {isLoading && <CenteredSpinner />}
      {isSuccess &&
        stats.map((stat, index) => (
          <Col key={`statcard-${index}`} sm="3" className="mb-4">
            <StatCard stat={stat} params={params} />
          </Col>
        ))}
    </>
  )
}

export default DashboardStats
