import React from 'react'
import Table from 'react-bootstrap/Table'
import ConflictBadge from 'duties/badges/conflict'
import useDuties from 'api/hooks/use-duties'
import TableLoading from 'shared/tables/table-loading'
import TableError from 'shared/tables/table-error'
import InternalConflict from 'duties/badges/internal-conflict'

function RoleOverview() {
  const filterParams = {
    page: 1,
    pageSize: 10,
    orderBy: 'role_sod_counts.conflicts_count',
    orderDirection: 'desc',
    groupBy: 'role'
  }

  const {
    isSuccess,
    isLoading,
    isError,
    data: roles
  } = useDuties({ params: filterParams })

  console.table(roles)

  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Role</th>
          <th>Internal Conflict</th>
          <th>Conflicts</th>
          <th>Employees</th>
          <th>High Risk Permissions</th>
          <th>Super User Permissions</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <TableLoading columns={6} />}
        {isError && <TableError columns={6} />}

        {isSuccess &&
          roles.data.map((role, index) => (
            <tr key={`role-index-${index}`}>
              <td>
                <a href={`/roles/${role.id}`}>{role.name}</a>
              </td>
              <td>
                <InternalConflict count={role.internal_conflicts} />
              </td>
              <td>
                <ConflictBadge count={role.conflicts_count} />
              </td>
              <td className='text-muted'>{role.employees_count}</td>
              <td className='text-muted'>{role.high_risk_permissions_count}</td>
              <td className='text-muted'>{role.superuser_permissions_count}</td>
            </tr>
          ))}
        <tr>
          <td colSpan={6} className="text-center">
            <a href="/roles">View All Roles</a>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default RoleOverview
