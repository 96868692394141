import React from 'react'
import Table from 'react-bootstrap/Table'
import ConflictBadge from 'duties/badges/conflict'
import useDuties from 'api/hooks/use-duties'
import TableLoading from 'shared/tables/table-loading'
import TableError from 'shared/tables/table-error'

function EmployeeOverview() {
  const filterParams = {
    page: 1,
    pageSize: 10,
    orderBy: 'employee_sod_counts.conflicts_count',
    orderDirection: 'desc',
    groupBy: 'employee'
  }

  const {
    isSuccess,
    isLoading,
    isError,
    data: duties
  } = useDuties({ params: filterParams })

  return (
    <Table responsive size="sm">
      <thead>
        <tr>
          <th>Employee</th>
          <th>Conflicts</th>
          <th>Roles Creating Conflicts</th>
          <th>High Risk Permissions</th>
          <th>Super User Permissions</th>
          <th>Permissions</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <TableLoading columns={5} />}
        {isError && <TableError columns={5} />}
        {isSuccess &&
          duties.data.map((d, index) => (
            <tr key={`employee-${index}`}>
              <td>
                <a href={`/users/${d.id}`}>{d.external_id}</a>
              </td>
              <td>
                <ConflictBadge count={d.conflicts_count} />
              </td>
              <td className="text-muted">{d.roles_with_conflicts_count}</td>
              <td className="text-muted">{d.high_risk_permissions_count}</td>
              <td className="text-muted">{d.superuser_permissions_count}</td>
              <td className="text-muted">{d.permissions_count}</td>
            </tr>
          ))}
        <tr>
          <td colSpan={6} className="text-center">
            <a href="/users">View All</a>
          </td>
        </tr>
      </tbody>
    </Table>
  )
}

export default EmployeeOverview
