import React from 'react'
import { useConflicts } from 'api/hooks/use-conflicts'
import Table from 'react-bootstrap/Table'
import TableLoading from 'shared/tables/table-loading'

function ConflictsOverview() {  
  const emptyParams = {
    search: '',
    orderBy: 'conflict_sod_counts.employees_count',
    orderDirection: 'desc',
    perPage: 10,
    page: 1
  }
  const params = emptyParams

  const { data, isLoading, isSuccess } = useConflicts({ params: params })

  return (
    <>
      <Table responsive size="sm">
        <thead>
          <tr>
            <th style={{ width: '70%' }}>Conflict</th>
            <th>Employees</th>
            <th>Roles</th>
          </tr>
        </thead>
        <tbody>
          {isLoading && (
            <TableLoading columns={3} />
          )}
          {isSuccess &&
            data.data.map((conflict, index) => (
              <tr key={`analytic-row-${conflict.id}`}>
                <td>
                  <a href={`/conflicts/${conflict.id}`}>{conflict.name}</a>
                  <div className="text-muted">{conflict.description}</div>
                </td>
                <td className="text-muted">{conflict.employees_count}</td>
                <td className="text-muted">{conflict.roles_count}</td>
              </tr>
            ))}
        </tbody>
        {isSuccess && (
          <tr>
            <td colSpan="3" className="text-center py-2">
              <a href="/conflicts">View All Conflicts</a>
            </td>
          </tr>
        )}
      </Table>
    </>
  )
}

export default ConflictsOverview
