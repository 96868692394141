import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import TableFooter from './footer'
import TableLoading from 'shared/tables/table-loading'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import TableFilters from 'transactions/table/filters/index'
import TableHeader from 'transactions/table/header'
import TableRow from 'transactions/table/row'
import useTransactions from 'api/hooks/use-transactions'
import DownloadTransactions from './actions'
import { pickPresentParams } from 'helpers/utils'

const defaultTableFilters = {
  page: 1,
  perPage: 50,
  orderBy: 'transaction_on',
  orderDirection: 'desc',
  external_id: '',
  department_id: '',
  vendors: [],
  excludedVendors: [],
  section_id: '',
  employees: [],
  excludedEmployees: [],
  analytic_ids: [],
  minimumAmount: '',
  maximumAmount: '',
  startOn: '',
  endOn: '',
  unviewedOnly: false,
  open: false,
  inProgress: false,
  exception: false,
  auditReportId: '',
  moleId: '',
  dashboardId: '',
  showCleared: '',
  saved: '',
  orgId: '',
  objectId: '',
  segOne: '',
  segTwo: '',
  segThree: '',
  segFour: '',
  segFive: '',
  orgs: [],
  objects: [],
  projects: [],
  segOnes: [],
  segTwos: [],
  segThrees: [],
  segFours: [],
  segFives: [],
  segSixes: [],
  segSevens: [],
  segEights: [],
  tag: '',
  disableJump: false,
  dateRangeSelected: '',
  flagCount: '',
  poIsPendingWorkflow: '',
  poMinReqToPoCreation: 0,
  apMinInvoiceToEntryDays: '',
  apMinEntryToPaymentDays: '',
  apMinTotalDaysToPayment: '',
  poStatusCode: '',
}

function TransactionTable({
  sectionId,
  perPage,
  vendors = [],
  excludedVendors = [],
  employees = [],
  excludedEmployees = [],
  minimumAmount = '',
  maximumAmount = '',
  analytic = [],
  orderBy,
  includeOrdering = false,
  columns,
  filtersEnabled = true,
  filters,
  footerEnabled = true,
  auditReportId = '',
  moleId = '',
  dashboardId = '',
  showCleared = '',
  saved = '',
  title = '',
  source = '',
  orgId = '',
  objectId = '',
  segOne = '',
  segTwo = '',
  segThree = '',
  segFour = '',
  segFive = '',
  orgs = [],
  objects = [],
  projects = [],
  segOnes = [],
  segTwos = [],
  segThrees = [],
  segFours = [],
  segFives = [],
  segSixes = [],
  segSevens = [],
  segEights = [],
  tag = '',
  startOn = '',
  endOn = '',
  downloadButton = true,
  defaultParams = {},
  riskScore = '',
  disableJump = false,
  poIsPendingWorkflow = '',
  poMinReqToPoCreation = 0,
  poStatusCode = '',
  apMinEntryToPaymentDays = 0,
  apMinTotalDaysToPayment = 0,
  apMinInvoiceToEntryDays = 0
}) {
  const [tableFilters, setTableFilters] = useState({
    ...defaultTableFilters,
    analytic_ids: analytic || [],
    section_id: sectionId,
    employees: employees || [],
    excludedEmployees: excludedEmployees || [],
    vendors: vendors || [],
    excludedVendors: excludedVendors || [],
    page: defaultParams.page || 1,
    perPage: perPage || 50,
    orderBy: orderBy || 'transaction_on',
    orderDirection: defaultParams.orderDirection || 'desc',
    startOn: startOn || '',
    endOn: endOn || '',
    saved: saved,
    moleId: moleId,
    dashboardId: dashboardId,
    showCleared: showCleared,
    orgId: orgId,
    objectId: objectId,
    segOne: segOne,
    segTwo: segTwo,
    segThree: segThree,
    segFour: segFour,
    segFive: segFive,
    orgs: orgs,
    objects: objects,
    projects: projects,
    segOnes: segOnes,
    segTwos: segTwos,
    segThrees: segThrees,
    segFours: segFours,
    segFives: segFives,
    segSixes: segSixes,
    segSevens: segSevens,
    segEights: segEights,
    tag: tag,
    auditReportId: auditReportId,
    riskScore: riskScore,
    poIsPendingWorkflow: poIsPendingWorkflow,
    poMinReqToPoCreation: poMinReqToPoCreation,
    poStatusCode: poStatusCode,
    apMinInvoiceToEntryDays: apMinInvoiceToEntryDays,
    apMinEntryToPaymentDays: apMinEntryToPaymentDays,
    apMinTotalDaysToPayment: apMinTotalDaysToPayment
  })
  const tableRef = useRef(null)
  const queryParams = {
    page: tableFilters.page,
    perPage: tableFilters.perPage,
    orderBy: tableFilters.orderBy,
    orderDirection: tableFilters.orderDirection,
    external_id: tableFilters.external_id || '',
    section_id: tableFilters.section_id || '',
    department_id: tableFilters.department_id || '',
    vendors: tableFilters.vendors.map((e) => e.id).join('+') || [],
    excluded_vendors:
      tableFilters.excludedVendors.map((v) => v.id).join('+') || [],
    employees: tableFilters.employees.map((e) => e.id).join('+') || [],
    excluded_employees:
      tableFilters.excludedEmployees.map((e) => e.id).join('+') || [],
    start_on: tableFilters.startOn || '',
    end_on: tableFilters.endOn || '',
    minimum_amount: tableFilters.minimumAmount,
    maximum_amount: tableFilters.maximumAmount,
    analytics: tableFilters.analytic_ids.map((a) => a.id).join('+') || [],
    unviewed: tableFilters.unviewedOnly,
    open: tableFilters.open,
    inprogress: tableFilters.inProgress,
    exception: tableFilters.exception,
    audit_report_id: tableFilters.auditReportId,
    mole_id: tableFilters.moleId,
    dashboard_id: tableFilters.dashboardId,
    show_cleared: tableFilters.showCleared,
    saved: tableFilters.saved,
    org_id: tableFilters.orgId,
    object_id: tableFilters.objectId,
    seg_one_id: tableFilters.segOne?.value || '',
    seg_two_id: tableFilters.segTwo?.value || '',
    seg_three_id: tableFilters.segThree?.value || '',
    seg_four_id: tableFilters.segFour?.value || '',
    seg_five_id: tableFilters.segFive?.value || '',
    org_ids: tableFilters.orgs.join('+') || [],
    object_ids: tableFilters.objects.join('+') || [],
    project_ids: tableFilters.projects.join('+') || [],
    seg_one_ids: tableFilters.segOnes.join('+') || [],
    seg_two_ids: tableFilters.segTwos.join('+') || [],
    seg_three_ids: tableFilters.segThrees.join('+') || [],
    seg_four_ids: tableFilters.segFours.join('+') || [],
    seg_five_ids: tableFilters.segFives.join('+') || [],
    seg_six_ids: tableFilters.segSixes.join('+') || [],
    seg_seven_ids: tableFilters.segSevens.join('+') || [],
    seg_eight_ids: tableFilters.segEights.join('+') || [],
    tag_id: tableFilters.tag,
    include_ordering: includeOrdering,
    flag_count: tableFilters.flagCount,
    risk_score: tableFilters.riskScore,
    po_is_pending_workflow: tableFilters.poIsPendingWorkflow,
    po_min_req_to_po_creation: tableFilters.poMinReqToPoCreation,
    po_po_status_code: tableFilters.poStatusCode,
    ap_min_invoice_to_entry_days: tableFilters.apMinInvoiceToEntryDays,
    ap_min_entry_to_payment_days: tableFilters.apMinEntryToPaymentDays,
    ap_min_total_days_to_payment: tableFilters.apMinTotalDaysToPayment
  }

  const {
    isSuccess,
    isLoading,
    data: transactions
  } = useTransactions({
    params: pickPresentParams(queryParams)
  })

  const transactionIds = isSuccess ? transactions.data.map((t) => t.id) : []

  // TODO: This is a hack to get the table to refresh when the filters change on dashboard page
  // This should be easier but having issues...
  // Might be fixed by passing in dashboardId now?
  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      endOn: endOn
    }))
  }, [endOn])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      startOn: startOn
    }))
  }, [startOn])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      riskScore: riskScore
    }))
  }, [riskScore])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      vendors: vendors || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendors.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      excludedVendors: excludedVendors || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedVendors.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      employees: employees || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      excluded_employees: excludedEmployees || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excludedEmployees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      section_id: sectionId
    }))
  }, [sectionId])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      minimumAmount: minimumAmount
    }))
  }, [minimumAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      maximumAmount: maximumAmount
    }))
  }, [maximumAmount])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      showCleared: showCleared
    }))
  }, [showCleared])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      orgs: orgs
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgs.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      objects: objects
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objects.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      projects: projects
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segOnes: segOnes
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segOnes.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segTwos: segTwos
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segTwos.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segThrees: segThrees
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segThrees.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFours: segFours
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFours.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segFives: segFives
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segFives.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSixes: segSixes
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSixes.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segSevens: segSevens
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segSevens.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      segEights: segEights
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segEights.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      analytic_ids: analytic || []
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytic.length])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poIsPendingWorkflow: poIsPendingWorkflow
    }))
  }, [poIsPendingWorkflow])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poMinReqToPoCreation: poMinReqToPoCreation
    }))
  }, [poMinReqToPoCreation])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      poStatusCode: poStatusCode
    }))
  }, [poStatusCode])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinEntryToPaymentDays: apMinEntryToPaymentDays
    }))
  }, [apMinEntryToPaymentDays])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinTotalDaysToPayment: apMinTotalDaysToPayment
    }))
  }, [apMinTotalDaysToPayment])

  useEffect(() => {
    setTableFilters((tableFilters) => ({
      ...tableFilters,
      apMinInvoiceToEntryDays: apMinInvoiceToEntryDays
    }))
  }, [apMinInvoiceToEntryDays])

  return (
    <div ref={tableRef}>
      {title.length > 0 && (
        <Row className="my-2">
          <Col>
            {' '}
            <h4>{title}</h4>
          </Col>
          <hr />
        </Row>
      )}
      {(filtersEnabled || downloadButton) && (
        <Card className="mb-4">
          <Card.Body>
            <Row className="g-2">
              {filtersEnabled && (
                <TableFilters
                  filters={filters}
                  tableFilters={tableFilters}
                  setTableFilters={setTableFilters}
                  tableRef={tableRef}
                  defaultTableFilters={defaultTableFilters}
                />
              )}
              <Col sm="auto">
                {downloadButton && (
                  <DownloadTransactions
                    defaultName={`${source} Transactions`}
                    source={source}
                    tableFilters={tableFilters}
                  />
                )}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      )}

      <div className="table-responsive">
        <table
          className="bg-white shadow-sm table mb-0 text-nowrap"
          style={{ border: '1px solid rgb(222 226 230)' }}
        >
          <TableHeader
            tableFilters={tableFilters}
            setTableFilters={setTableFilters}
            columns={columns}
            includeOrdering={includeOrdering}
          />
          <tbody>
            {isLoading && <TableLoading columns={7} />}
            {isSuccess && transactions.data.length === 0 && (
              <tr>
                <td colSpan={columns.length} className="text-center">
                  <div className="my-4">No transactions found</div>
                </td>
              </tr>
            )}
            {isSuccess &&
              transactions.data.map((t, i) => (
                <TableRow
                  transaction={t}
                  key={`row-${t.id}-${i}`}
                  columns={columns}
                  auditReportId={auditReportId}
                  moleId={moleId}
                  transactions={transactionIds}
                  dashboardId={dashboardId}
                />
              ))}
          </tbody>
        </table>
      </div>

      <Row className="mt-4">
        <Col>
          {isSuccess && footerEnabled && (
            <TableFooter
              meta={transactions.meta}
              params={tableFilters}
              setParams={setTableFilters}
              disableJump={disableJump}
            />
          )}
        </Col>
      </Row>
    </div>
  )
}

TransactionTable.propTypes = {
  section: PropTypes.number,
  vendors: PropTypes.array,
  employees: PropTypes.array,
  analytics: PropTypes.array,
  columns: PropTypes.array.isRequired,
  filtersEnabled: PropTypes.bool,
  auditReportId: PropTypes.string,
  moleId: PropTypes.string
}

export default TransactionTable
