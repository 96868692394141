import React from 'react'
import { usePermissions } from 'api/hooks/use-permissions'
import Table from 'react-bootstrap/Table'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import TableLoading from 'shared/tables/table-loading'
import TableError from 'shared/tables/table-error'
import HighRiskPermissionBadge from 'duties/badges/high-risk-permission'
import SuperUserPermissionBadge from 'duties/badges/superuser-permission'

function PermissionOverview() {
  const navigate = useNavigate()
  const params = {
    page: 1,
    perPage: 10,
    orderBy: 'employees_count',
    orderDirection: 'desc'
  }

  const {
    isLoading,
    isError,
    isSuccess,
    data: permissions
  } = usePermissions({ params })

  return (
    <>
      {isSuccess && (
        <Table
          hover
          responsive
          size="sm"
          className="border shadow-sm bg-white"
          style={{ border: '1px solid rgb(222 226 230)' }}
        >
          <thead>
            <tr>
              <th>Name</th>
              <th>Caption</th>
              <th>High Risk</th>
              <th>Super User</th>
              <th>Employee Count</th>
              <th>Role Count</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <TableLoading colSpan={6} />
            )}
            {isError && (
              <TableError colSpan={6} />
            )}
            {permissions.data.map((permission, index) => (
              <tr
                key={`permission-index-${index}`}
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/permissions/${permission.id}`)}
              >
                <td>{permission.name}</td>
                <td>{permission.category_caption}</td>
                <td>
                  {permission.is_high_risk && (
                    <HighRiskPermissionBadge count={1} displayCount={false} />
                  )}
                </td>
                <td>
                  {permission.is_superuser && (
                    <SuperUserPermissionBadge count={1} displayCount={false} />
                  )}
                </td>
                <td>{permission.employees_count}</td>
                <td>{permission.roles_count}</td>
              </tr>
            ))}
            <tr>
              <td colSpan="6">
                <div className="d-flex justify-content-center">
                  <Button
                    variant="link"
                    onClick={() => navigate('/permissions')}
                  >
                    View All
                  </Button>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      )}
    </>
  )
}

export default PermissionOverview
