import React, { useState } from 'react'
import Stats from '../stats'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import EmployeeOverview from './employee-preview'
import RoleOverview from './role-preview'
import ConflictOverview from './conflict-preview'
import PermissionOverview from './permission-preview'
import AuditLogOverview from './audit-log/index'
import { Button } from 'react-bootstrap'

function OverviewTab() {
  const [activeTab, setActiveTab] = useState('audit-log')
  return (
    <>
      <Row>
        <Col sm={12}>
          <div className="mb-4">
            <Stats />
          </div>
        </Col>
        <Col sm={8}></Col>
      </Row>
      <Row className="mt-4">
        <Col sm={12}>
          <Card>
            <Card.Header className="bg-white">
              <span className="mt-1 pe-2 fw-light">Top Risk By</span>
              <span className="border-end border-2 border-light me-2"></span>
              <Button
                variant={activeTab === 'audit-log' ? 'primary' : 'light'}
                onClick={() => setActiveTab('audit-log')}
                className="me-2"
              >
                Changes
              </Button>
              <Button
                variant={activeTab === 'conflict' ? 'primary' : 'light'}
                onClick={() => setActiveTab('conflict')}
                className="me-2"
              >
                Conflict
              </Button>

              <Button
                variant={activeTab === 'role' ? 'primary' : 'light'}
                onClick={() => setActiveTab('role')}
                className="me-2"
              >
                Role
              </Button>
              <Button
                variant={activeTab === 'employee' ? 'primary' : 'light'}
                onClick={() => setActiveTab('employee')}
                className="me-2"
              >
                Employee
              </Button>
              <Button
                variant={activeTab === 'permission' ? 'primary' : 'light'}
                onClick={() => setActiveTab('permission')}
              >
                Permission
              </Button>
            </Card.Header>
            <Card.Body>
              {activeTab === 'audit-log' && <AuditLogOverview />}
              {activeTab === 'conflict' && <ConflictOverview />}
              {activeTab === 'role' && <RoleOverview />}
              {activeTab === 'employee' && <EmployeeOverview />}
              {activeTab === 'permission' && <PermissionOverview />}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default OverviewTab
